.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*
.btn {
  background-color: #1d1bb8;
  color: #fff;
  padding: 1.3em;
  letter-spacing: 2px;
  border-radius: 2px;
  border: solid 1px #141eab;
  font-size: 0.7em;
}
.search-bar {
  top: 50%;
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 1em;
  position: relative;
}

.search-btn {
  text-transform: uppercase;
  display: inline-block;
  margin-left: 1em;
}

.search-btn:hover {
  cursor: pointer;
}

.search-box {
  background-color: #ccc;
  border: solid 1px #ccc;
  border-radius: 3px;
}

.search-box:hover {
  cursor: pointer;
}

.search-icon {
  color: #605e5e;
  position: absolute;
  left: 3px;
  align-self: center;
}

.search-box:focus {
  background-color: #fff;
}

.search-box:focus .search-icon {
  color:black;}
*/
